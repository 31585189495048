
.userReview {
	width: 100%;
	min-height: 100vh;
	background: rgb(21, 67, 96);
	background: -moz-linear-gradient(297deg, rgba(21, 67, 96, 1) 35%, rgba(46, 134, 193, 1) 100%);
	background: -webkit-linear-gradient(297deg, rgba(21, 67, 96, 1) 35%, rgba(46, 134, 193, 1) 100%);
	background: linear-gradient(297deg, rgba(21, 67, 96, 1) 35%, rgba(46, 134, 193, 1) 100%);

	.holder {
		max-width: 800px;
		margin: 0 auto;
		padding: 50px 0;
		text-align: center;

		.error {
			color: #ec7063;
			font-size: 16px;
			font-weight: bold;
			margin-top: 30px; }

		.thanks {
			font-size: 25px;
			color: white;
			text-align: center;
			margin: 100px 0 30px 0; }

		.step {
			width: 100%;
			margin-bottom: 50px;

			.title {
				font-weight: bold;
				font-size: 20px;
				text-transform: uppercase;
				color: #f4f6f6; }

			.description {
				font-size: 16px;
				color: #d6eaf8;
				margin-top: 10px; }

			input {
				margin-top: 30px;
				width: 50%;
				padding: 10px;
				border: none;
				border-radius: 6px; }

			textarea {
				margin-top: 30px;
				width: 80%;
				padding: 10px;
				border-radius: 6px;
				border: none; }

			.bold {
				color: #f7dc6f; }

			.starBar {
				color: #f7dc6f;
				margin-top: 20px;
				font-size: 30px;

				i {
					margin: 0 5px;
					cursor: pointer; } } }

		button {
			width: 100%;
			max-width: 300px;
			margin: 20px 10% 0 10%;
			padding: 10px 20px;
			background-color: transparent;
			color: #82e0aa;
			text-transform: uppercase;
			cursor: pointer;
			border: 2px solid #82e0aa;
			font-size: 18px;
			transition: all .2s ease-in-out;
			font-weight: bold;

			&:hover {
				background-color: #82e0aa;
				color: white; } } } }
