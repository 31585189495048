
.instagramFeed {
	width: 100%;
	margin-top: 100px;

	.titleBar {
		width: 100%;
		text-align: center;
		position: relative;
		margin-bottom: 30px;

		.border {
			width: 100%;
			height: 1px;
			background-color: #2980b9;
			position: absolute;
			top: 50%; }

		.title {
			display: flex;
			flex-direction: row;
			font-size: 35px;
			justify-content: center;
			background-color: white;
			padding: 10px;
			width: 30%;
			margin: 0 auto;
			position: relative;
			z-index: 100;

			i {
				margin: 0 20px;
				color: #2980b9;
				cursor: pointer; } }

		.hashBar {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: center;

			.hash {
				margin: 0 10px;
				color: #85c1e9;
				cursor: pointer; } } }

	.feed {
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;

		.item {
			height: 300px;
			width: 30%;
			background-size: cover;
			background-position: center;
			margin: 10px;
			border-radius: 10px;
			-webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.41);
			-moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.41);
			box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.41);
			cursor: pointer;
			position: relative;

			.overlay {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0; } } } }

@media (max-width: 550px) {
	.instagramFeed {
		.titleBar {
			.border {
				top: 30%; }

			.title {
				width: 90%; }

			.hashBar {
				flex-direction: column;
				margin-top: 10px; } }

		.feed {
			.item {
				width: 45%;
				margin: 5px; } } } }
