
.category {
	width: 100%;

	.holder {
		margin-top: 100px;

		.title {
			width: 100%;
			font-size: 25px;
			text-transform: uppercase;
			color: #566573; }

		.description {
			width: 100%;
			font-size: 18px;
			color: #abb2b9; }

		.cat {
			width: 100%;
			padding: 20px 0;
			margin-top: 70px;

			.information {
				padding: 0 20px;
				width: 75%;

				.title {
					font-size: 18px;
					color: #566573;
					text-transform: uppercase; }

				.description {
					font-size: 16px;
					color: #abb2b9; }

				.grid {
					display: flex;
					flex-direction: row;
					justify-content: space-around;
					margin-top: 10px;

					img {
						width: 20%;
						-webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.41);
						-moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.41);
						box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.41); } }

				.buttonBar {
					width: 100%;
					text-align: right;
					margin-top: 30px; } }

			.mainImage {
				width: 25%;
				-webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.41);
				-moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.41);
				box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.41); } }

		.cat:nth-child(even) {

			.mainImage {
				float: right; }

			.information {
				float: left;

				button {
					float: left;
					margin-left: 15px; } } }

		.cat:nth-child(odd) {

			.mainImage {
				float: left; }

			.information {
				float: right;

				button {
					float: right;
					margin-right: 15px; } } } } }


@media (max-width: 550px) {
	.category {
		.holder {
			.cat {
				.information {
					width: 100%;
					margin: 30px 0; }

				.mainImage {
					width: 100%; } } } } }
