@import "src/static/sass/fonts";


body {
	padding: 0;
	margin: 0;
	overflow-x: hidden; }

* {
	box-sizing: border-box;
	font-family: $ubuntu; }

.clear {
	clear: both; }

a {
	text-decoration: none; }
