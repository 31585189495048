
.footer {
	width: 100%;
	max-width: 600px;
	margin: 150px auto 40px auto;
	text-align: center;

	img {
		width: 100%;
		max-width: 300px; }

	.socialBar {
		width: 100%;
		max-width: 200px;
		margin: 0 auto;
		font-size: 30px;
		color: #2874a6;
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;

		a {
			text-decoration: none;
			color: #2874a6; } }

	.address {
		width: 100%;
		font-size: 18px;
		margin-top: 15px;
		color: #21618c; }

	.copy {
		width: 100%;
		font-size: 12px;
		color: #abb2b9; } }

@media (max-width: 550px) {
	.footer {
		.address {
			font-size: 15px; } } }
