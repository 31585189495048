$title-color: #566573;

.index {
	width: 100%;

	.boxMenu {
		margin-top: 100px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.item {
			width: 100%;
			padding: 10px;

			img {
				width: 100%; }

			.title {
				font-size: 18px;
				text-transform: uppercase;
				color: $title-color;
				margin-top: 20px; }

			.description {
				font-size: 14px;
				color: lighten($title-color, 20%);
				margin: 10px 0 30px 0; } } }

	.whoWeAre {
		margin-top: 100px;
		padding: 50px 0;
		background: rgb(21, 67, 96);
		background: -moz-linear-gradient(297deg, rgba(21, 67, 96, 1) 35%, rgba(46, 134, 193, 1) 100%);
		background: -webkit-linear-gradient(297deg, rgba(21, 67, 96, 1) 35%, rgba(46, 134, 193, 1) 100%);
		background: linear-gradient(297deg, rgba(21, 67, 96, 1) 35%, rgba(46, 134, 193, 1) 100%);

		.text {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.block {
				color: white;
				width: 50%;
				padding: 0 10px;
				font-size: 18px;
				line-height: 30px;
				margin-top: 30px;

				.bold {
					font-weight: bold;
					text-transform: uppercase;
					color: #f7dc6f; } } }

		.reviews {
			width: 100%;
			color: white;
			margin-top: 100px;

			.review {
				width: 90%;
				margin: 0 auto;

				.body {
					width: 100%;
					text-align: center;
					font-size: 30px; }

				.stars {
					width: 100%;
					text-align: center;
					color: #f7dc6f;
					font-size: 25px;
					margin-top: 30px; }

				.name {
					width: 100%;
					text-align: center;
					margin-top: 5px;
					text-transform: uppercase; } } } } }


@media (max-width: 550px) {
	.index {
		.boxMenu {
			flex-direction: column;

			.item {
				margin-bottom: 30px; } }

		.whoWeAre {
			.text {
				flex-direction: column;

				.block {
					margin-bottom: 15px;
					width: 100%;
					text-align: center; } } } } }
