body {
  width: calc(100%);
  margin: 0;
  padding: 0;
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; }

* {
  box-sizing: border-box; }

.clear {
  width: 100%;
  height: 1px;
  clear: both; }
